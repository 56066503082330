export let UserRoles = {
  orgAdmin: 7,
  orgAssistant: 5,
  orgUser: 4,
  workspaceOwner: 3,
  workspaceAdmin: 2,
  workspaceUser: 1,
}

export function getRole(role: number) {
  let entries = Object.entries(UserRoles)
  let roleEntry = entries.find(([key, value]) => value === role)
  return roleEntry?.[0]
}
